import { FunctionProductCategory } from "./FunctionProductCategory";
import { CourseGroup, MenuHeadingGroupType, MenuOrderingStyle } from "./Menu";
import { PickupDeliverySchedule } from "./PickupDeliverySchedule";

export enum OrderSource {
  Till,
  OrderingWidget,
  TakeawayWidget,
  QRCode,
}

export enum OrderType {
  TableService,
  CounterService,
  Pickup,
  Delivery,
}

export interface Orders {
  [orderId: string]: Order;
}

export interface Order {
  orderItems: OrderItems;
  date: string;
  /**
   * @deprecated Use {@link Order.firstName} and {@link Order.lastName} instead.
   */
  name?: string;
  firstName?: string; // firstName of customer who ordered this
  lastName?: string; // lastName of customer who ordered this
  uid?: string;
  bookingCustomerName?: string;
  pax?: number;
  svip?: boolean;
  vip?: boolean;
  number?: string;
  intervalId?: number;
  restaurantId: string;
  menuId: string;
  bookingId?: string;
  refBookingId?: string; // refBookingId belongs to booking that paid for pay laters in this booking
  note?: string;
  functionBookingId?: string;
  bookingRef?: string;
  mealId?: string;
  customerId?: string;
  // guestId?: string;
  orderSource: OrderSource;
  orderType: OrderType;
  orderNumber: number;
  createdAt: any;
  fcmToken?: string;
  _key?: string;
  operatorId?: string;
  tillId?: string;
  isManualCharge?: boolean;
  schedule?: PickupDeliverySchedule;
  tableNumber?: string;
  batchingTableGroupId?: string;
  orderedFromDifferentBookingTable?: boolean;
  bucketIds?: { [bucketId: string]: boolean } /** Currently only used for QR Ordering only orders when doing casual batching only.  */;
}

export interface OrderItems {
  [orderItemId: string]: OrderItem;
}

export interface OrderModifier {
  productId: string;
  productSizeId: string;
  quantity?: number;
  name: string;
}

export interface OrderModifiers {
  [modifierGroupId: string]: OrderModifier;
}

export interface OrderPreparations {
  [id: string]: { id: string; name: string }[];
}

export interface OrderAdditions {
  [additionGroupId: string]: OrderAdditionProduct;
}

export interface OrderAdditionProduct {
  [additionProductId: string]: OrderItem;
}

export enum OrderItemStatus {
  InBasket, // Item is in basket
  WaitingToBeSent, // Item is sent from basket but still not sent to kitchen
  Sent, // Item is sent to kitchen
  Prepared, // Kitchen has prepared the item
  Served, // Kitchen has served and completed the item from their part
}

export enum OrderMode {
  PreService,
  InService,
}

export interface OrderItemUpsells {
  [upsellGroupId: string]: {
    [productId: string]: OrderItem;
  };
}

export interface OrderItemUpgrades {
  [upgradeGroupId: string]: {
    [productId: string]: OrderItem;
  };
}

export interface OverrideHeadings {
  [orderItemId: string]: {
    productId: string;
    overrideHeadingIds: string[];
    overrideHeadingId: string;
  };
}

export interface ItemOverrideCourseGroups {
  [orderItemId: string]: {
    productId: string;
    overrideCourseGroupsOptions: CourseGroup[];
    overrideCourseGroupId: string;
  };
}

export interface OrderItem {
  _key?: string;
  orderStatus: OrderItemStatus;
  paidQuantity: number; // quantity that has been paid
  orderMode?: OrderMode;
  payLater?: boolean;
  payNow?: boolean;
  name: string;
  time: number;
  sizeId: string;
  productId: string;
  modifiers?: OrderModifiers;
  additions?: OrderAdditions;
  upsells?: OrderItemUpsells;
  upgrades?: OrderItemUpgrades;
  addedToBasket?: boolean;
  quantity: number;
  customerId?: string;
  preparations?: OrderPreparations;
  orderItemId: string;
  orderId: string;
  comboGroup?: OrderItemProducts;
  headingId: string;
  groupHeadingId?: string;
  isSpecialProduct: boolean;
  inclusive: boolean;
  inclusiveProduct?: boolean;
  overrideOrderingStyle?: MenuOrderingStyle;
  createdAt: number;
  preparedTime?: number;
  completedTime?: number;
  menu: string;
  note?: string;
  functionProductCategory?: FunctionProductCategory;
  packageId?: string;
  subHeadingId?: string;
  menuOptionId?: string;
  manualAmount?: number;
  beverageMenuPackageId?: string;
  courseGroupKey?: string;
  addedAsUpsell?: boolean;
}

export enum DocketPrintStatus {
  NotPrinted,
  Printed,
  Error,
}

export interface DocketItems {
  [docketItemId: string]: DocketItem;
}

export enum DocketType {
  Standard,
  Removed,
}

export interface Docket {
  _key?: string;
  id?: string;
  bookingId?: string;
  printerAreaIds: string[];
  functionBookingId?: string;
  notificationDeleted?: boolean;
  bookingRef?: string;
  bucketId?: string;
  batchSettingId?: string;
  createdAt: number;
  customerName: string;
  date: number;
  docketGenerationTime: number;
  docketItems: DocketItems;
  orderSource: OrderSource;
  orderType: OrderType;
  pax?: number;
  printStatus: DocketPrintStatus;
  restaurantId: string;
  tableNo?: string;
  time: string;
  orderNumber?: number;
  courseGroups?: Array<string>;
  hidden: {
    one: boolean;
    prep: boolean;
    serve: boolean;
  };
  canUseMenuHeadingToGroup: boolean;
  operatorId?: string;
  customerId?: string;
  type: DocketType;
  bookingTableNo?: string;
}

export interface DocketItemCombo extends Pick<DocketItem, "additions" | "name" | "preparations" | "modifiers" | "quantity" | "size" | "printing"> {
  name: string;
}

export interface DocketItem {
  additions: Array<{ name: string; quantity: number; size?: string }>;
  consolidatedItems?: Array<{ orderId: string; orderItemId: string }>;
  heading: string;
  modifiers: Array<{ name: string; quantity: number; size?: string }>;
  combo: DocketItemCombo[];
  name: string;
  orderId: string;
  orderItemId: string;
  preparations: Array<{ name: string; quantity: number; size?: string }>;
  printing: Array<{ macAddress: string; printStatus: DocketPrintStatus; reason: string }>;
  quantity: number;
  size: string;
  hideSize?: boolean;
  courseGroupIndex?: number;
  courseGroupKey?: string;
  note?: string;
  customerNames?: { [customerId: string]: string };
  groupType?: MenuHeadingGroupType;
  tableNumber: string;
}
/**
 * @todo: this will be removed later when the feature on ordering app to enter table number will be developed so that all ordering app session will have tableNumber and table group id
 * */
export const DefaultIdForEmptyTableGroupId = "emptyTableGroupId";

export interface BucketsDoc {
  data: Buckets;
  date: number;
  restaurantId: string;
}
export interface Buckets {
  [id: string]: Bucket;
}

export interface Bucket {
  bookingId: string;
  batchingTableGroupId: string;
  batchSettingId: string;
  completed?: boolean;
  completedAt?: number;
  expiryTime: number;
  menuId: string;
  startTime: number;
  time: number;
  timeExtensions?: Array<number>;
  isCasual?: boolean;
  id?: string;
}

export interface OrderingPrompt {
  courseGroupId: string;
  readByCustomers?: { [customerId: string]: boolean };
  createdAt: number;
}

/**
 * Static Course Group to be used in till if there is no fixed order of service menu. Its mocking the structure of courseGroups in batchSetting.
 * menuHeadngIds is there just to mock the batchSettings coursegroup structure
 */
export const manualCourseGroups = Object.freeze([
  { _key: "1", name: "1st Course", courseAbbreviation: "1", order: 1, menuHeadingIds: [] },
  { _key: "2", name: "2nd Course", courseAbbreviation: "2", order: 2, menuHeadingIds: [] },
  { _key: "3", name: "3rd Course", courseAbbreviation: "3", order: 3, menuHeadingIds: [] },
]);

export interface AccumulatedOrderItemsByMenuHeading {
  [menuHeadingId: string]: { paidQuantity: number; quantity: number };
}

export interface DeletedOrders {
  [deletedOrderId: string]: DeletedOrder;
}

export interface DeletedOrder extends Omit<Order, "orderItems"> {
  orderItems: DeletedOrderItems;
}

export interface DeletedOrderItems {
  [orderItemId: string]: DeletedOrderItem;
}

export interface DeletedOrderItem extends OrderItem {
  deletionLogs: { deletedReason?: string; deletedAt: number; deletedBy: string; deletedQuantity: number }[];
}

export interface BookingOrderingNextCourseAwayData {
  currentOrderingCourseAway: string;
  previousOrderingCourseAway: string;
  courseAwayLogs: { courseId: string; courseAwayTime: number }[];
}

export interface BookingOrderingData {
  _key: string;
  date: number;
  mealId: string;
  bookingId: string;
  restaurantId: string;
  nextCourseAwayData?: BookingOrderingNextCourseAwayData;
  orderingPrompts?: { [id: string]: OrderingPrompt };
  createdAt: number;
  updatedAt?: number;
}

export interface OrderItemProducts {
  [productId: string]: OrderItem;
}

export interface BatchingBuckets {
  [bucketId: string]: Bucket;
}
