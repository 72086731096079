import classNames from "classnames";
import firebase from "firebase/compat/app";
import _, { values } from "lodash";
import pako from "pako";
import React, { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { shallowEqual, useDispatch } from "react-redux";
import { Badge, Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { catchExceptionCallback, getConfig, getMealsFiltered } from "../../core/utilities";
import { useAppSelector } from "../../hooks/hook";
import { Labels } from "../../resbutler-utils/types/Labels";
import { Meals } from "../../resbutler-utils/types/Meals";
import { Menus } from "../../resbutler-utils/types/Menu";
import { Product, Product as ProductProps, ProductTypeId, StockLimits } from "../../resbutler-utils/types/product";
import { UPDATE_STOCK_LIMIT, initialStockLimits } from "../../store/reducers/stockLimit.reducer";
import { FilterProps } from "./Filter";
import STOCK_CONSTANT from "./constant";
import Additions from "./tabs/additions/Additions";
import Modifier from "./tabs/modifiers/Modifier";
import ProductsComponent from "./tabs/product/Product";
import Special from "./tabs/special/Special";
import Upsell from "./tabs/upsells/Upsell";

function validate(values) {
  const errors = {};
  const overrideKeys = ["products", "modifiers", "additions", "upsells", "specials"];
  for (let overrideKey of overrideKeys) {
    const items = values[overrideKey] || [];
    Object.entries(items).forEach(([productId, sizeInfo]: any) => {
      Object.entries(sizeInfo).forEach(([sizeId, stockInfo]: any) => {
        if (stockInfo.isOverride && !stockInfo.status) {
          _.set(errors, `${overrideKey}.${productId}.${sizeId}.status`, "Please select one option");
        }
        if (stockInfo.isOverride && stockInfo.status === STOCK_CONSTANT.status.APPLIED && stockInfo.appliedTo) {
          if (stockInfo.appliedTo === STOCK_CONSTANT.appliedTo.TOTAL && isNaN(stockInfo?.stock?.current)) {
            _.set(errors, `${overrideKey}.${productId}.${sizeId}.stock.current`, "Current stock is required.");
          }
          if (stockInfo.appliedTo === STOCK_CONSTANT.appliedTo.MENUS && (!stockInfo?.stock?.menus || !Object.keys(stockInfo?.stock?.menus || {}).length)) {
            _.set(errors, `${overrideKey}.${productId}.${sizeId}.stock.menus`, "Menu is required.");
          }
          if (stockInfo.appliedTo === STOCK_CONSTANT.appliedTo.DINE_IN_TAKEAWAY && !stockInfo?.stock?.dineIn && !stockInfo?.stock?.takeaway) {
            _.set(errors, `${overrideKey}.${productId}.${sizeId}.stock.dineIn`, "Please select one of the available option");
          }
          if (stockInfo.appliedTo === STOCK_CONSTANT.appliedTo.DINE_IN_TAKEAWAY && stockInfo?.stock?.dineIn === null && stockInfo?.stock?.takeaway === null) {
            _.set(errors, `${overrideKey}.${productId}.${sizeId}.stock.dineIn`, "Please select one of the available option");
          }
        }
        if (stockInfo.isOverride && !stockInfo.appliedTo) {
          _.set(errors, `${overrideKey}.${productId}.${sizeId}.appliedTo`, "Please select one of the available option");
        }
      });
    });
  }
  return errors;
}

enum CollapseTabType {
  Product,
  Modifier,
  Upsell,
  Addition,
  Special,
}

const MenuItemModal = React.memo<{ setShowModal: any; restaurantId: string; date: string }>(({ setShowModal, restaurantId, date }) => {
  const [filters, setFilters] = useState<FilterProps>({
    query: "",
    overrides: false,
    menuId: "",
    typeIds: [],
  });
  const dispatch = useDispatch();
  const { tenantId } = getConfig();
  const [menus, setMenus] = useState<Menus>({});
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [collapseTab, setCollapseTab] = useState<CollapseTabType>(null);
  const [menuProducts, setMenuProducts] = useState<ProductProps[]>([]);
  const [stocks, setStocks] = useState(initialStockLimits);
  const [filteredProducts, setFilterProducts] = useState<Product[]>([]);

  // shared state
  const {
    menus: allMenus,
    stockLimits,
    restaurants,
    productSizes,
  } = useAppSelector(
    (state) => ({
      menus: state.root.menus,
      stockLimits: state.stockLimits,
      restaurants: state.root.restaurants,
      productSizes: state.root.productSizes,
    }),
    shallowEqual
  );

  // save form values
  const onSubmit = async (values: StockLimits) => {
    try {
      await firebase.database().ref(`${tenantId}/maestros/${date}/${restaurantId}/date/stockLimits`).set(values);
      dispatch({ type: UPDATE_STOCK_LIMIT, data: values });
      setShowModal(false);
    } catch (error) {
      catchExceptionCallback(error);
    }
  };

  useEffect(() => {
    async function init() {
      try {
        setInitializing(true);
        const initialStock = JSON.parse(JSON.stringify(stocks)) as StockLimits;
        const mergedStockLimits = Object.assign({}, initialStock, stockLimits);
        const results1 = await Promise.all([firebase.database().ref(`${tenantId}/maestroDataLabels`).orderByChild("restaurantId").equalTo(restaurantId).once("value"), firebase.database().ref(`${tenantId}/meals`).orderByChild("enabled").equalTo(true).once("value")]);
        const labels = (results1[0].val() || {}) as Labels;
        const meals = (results1[1].val() || {}) as Meals;
        const currentRestaurant = restaurants[restaurantId];
        const restaurantMeals = getMealsFiltered(date, restaurantId, labels, meals, currentRestaurant.zoneId);
        const mealsIds = Object.keys(restaurantMeals || {});
        const restaurantLabelPromises = mealsIds.map((mealId) => firebase.database().ref(`${tenantId}/maestros/${date}/${restaurantId}/maestro/${mealId}/label`).once("value"));
        const labelPromiseResults = await Promise.all(restaurantLabelPromises);
        const restaurantLabels = labelPromiseResults.map((labelPromiseResult) => labelPromiseResult.val());
        const maestroDataPromises = restaurantLabels.filter((label) => !!label?.labelId && label?.version).map((label) => firebase.database().ref(`${tenantId}/maestroData/${label.labelId}/${label.version}/style/menuIds`).once("value"));
        const results2 = await Promise.all(maestroDataPromises);
        let menuIds = results2.map((result) => Object.keys(result.val())).flat();
        menuIds = _.uniq(menuIds);
        const activeMenus = menuIds.reduce((accu, menuId) => {
          if (allMenus?.[menuId]?.enabled) {
            accu[menuId] = allMenus[menuId];
          }
          return accu;
        }, {} as Menus);
        setMenus(activeMenus);
        setStocks(mergedStockLimits);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setInitializing(false);
      }
    }

    if (restaurantId) init();
  }, [restaurantId, date, stockLimits]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { client, config } = getConfig();
      try {
        const response = await fetch(`https://storage.googleapis.com/${config.publicStorageBucket}/${client}/menus/all-products.json`);
        const arrayBuffer = await response.arrayBuffer();
        const products = JSON.parse(new TextDecoder().decode(pako.inflate(arrayBuffer)));
        const products1 = _.filter(products, (product) => product.typeId != ProductTypeId.fixedPriceMenu && product.typeId != ProductTypeId.fixedPrice && product.restaurants[restaurantId]?.enabled);
        setMenuProducts(products1);
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let result = [...menuProducts];
    if (filters.query && filters.query.trim()?.length) {
      result = result.filter(({ name }) => _.includes(name.toLowerCase(), filters.query.trim().toLowerCase()));
    }
    setFilterProducts(result);
  }, [filters.query, menuProducts]);

  const ExpandableCard = ({ collapseValue, title, count, children }: { collapseValue: CollapseTabType; title: string; count: number; children: any }) => {
    return (
      <Card className="card-default mt-2">
        <CardHeader className="bg-light">
          <Row>
            <Col className="d-flex align-items-center">
              <Button type="button" className="mr-2" onClick={() => setCollapseTab(collapseTab !== collapseValue ? collapseValue : null)}>
                <i
                  className={classNames("fa", {
                    "fa-chevron-right": collapseTab !== collapseValue,
                    "fa-chevron-down": collapseTab === collapseValue,
                  })}
                  aria-hidden="true"
                />
              </Button>
              <strong className="d-block">{title} </strong>
              {count > 0 && (
                <Badge pill className="ml-1">
                  {count || 0}
                </Badge>
              )}
              {initializing && (
                <Spinner size="sm" className="ml-2">
                  {""}
                </Spinner>
              )}
            </Col>
          </Row>
        </CardHeader>
        {collapseTab == collapseValue ? <CardBody>{children}</CardBody> : null}
      </Card>
    );
  };

  return (
    <Form<StockLimits> onSubmit={onSubmit} initialValues={stocks} validate={validate}>
      {({ handleSubmit, submitting, values: vls }) => (
        <Modal top scrollable size="xl" isOpen toggle={() => setShowModal(false)}>
          <form onSubmit={handleSubmit} className="modal-content">
            <ModalHeader toggle={() => setShowModal(false)}>Menu Items</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-4">
                  <input id="search" type="search" className="form-control" value={filters.query} placeholder="Search..." onChange={(e) => setFilters({ ...filters, query: e.target.value })} />
                </div>
                <div className="checkbox c-checkbox col-4">
                  <label className="mb-2 pr-2" htmlFor="show-overrides">
                    <input
                      id="show-overrides"
                      type="checkbox"
                      onChange={(e) => {
                        setFilters({ ...filters, overrides: e.target.checked });
                      }}
                      checked={filters.overrides}
                    />
                    <span className="fa fa-check" />
                    Overrides Only
                  </label>
                </div>
              </div>
              <ExpandableCard collapseValue={CollapseTabType.Product} title={"Product"} count={vls.count?.products}>
                {collapseTab === CollapseTabType.Product ? <ProductsComponent menus={menus} restaurantId={restaurantId} productSizes={productSizes} filteredProducts={filters.overrides ? filteredProducts.filter(({ id }) => values(vls.products[id]).some(({ isOverride = false }) => !!isOverride)) : filteredProducts} /> : null}
              </ExpandableCard>
              <ExpandableCard collapseValue={CollapseTabType.Modifier} title={"Modifier"} count={vls.count?.modifiers}>
                {collapseTab === CollapseTabType.Modifier ? <Modifier menus={menus} restaurantId={restaurantId} filteredProducts={filters.overrides ? filteredProducts.filter(({ id }) => values(vls.modifiers[id]).some(({ isOverride = false }) => !!isOverride)) : filteredProducts} loading={loading} filters={filters} productSizes={productSizes} /> : null}
              </ExpandableCard>
              {/* Upsell */}
              <ExpandableCard collapseValue={CollapseTabType.Upsell} title={"Upsells"} count={vls.count?.upsells}>
                {collapseTab === CollapseTabType.Upsell ? <Upsell menus={menus} restaurantId={restaurantId} filteredProducts={filters.overrides ? filteredProducts.filter(({ id }) => values(vls.upsells[id]).some(({ isOverride = false }) => !!isOverride)) : filteredProducts} loading={loading} filters={filters} productSizes={productSizes} /> : null}
              </ExpandableCard>
              {/* Additionals */}
              <ExpandableCard collapseValue={CollapseTabType.Addition} title={"Addition"} count={vls.count?.additions}>
                {collapseTab === CollapseTabType.Addition ? <Additions menus={menus} restaurantId={restaurantId} filteredProducts={filters.overrides ? filteredProducts.filter(({ id }) => values(vls.additions[id]).some(({ isOverride = false }) => !!isOverride)) : filteredProducts} loading={loading} filters={filters} productSizes={productSizes} /> : null}
              </ExpandableCard>
              {/* special */}
              <ExpandableCard collapseValue={CollapseTabType.Special} title={"Special"} count={vls.count?.specials}>
                {collapseTab === CollapseTabType.Special ? <Special menus={menus} stockLimits={stockLimits} restaurantId={restaurantId} productSizes={productSizes} /> : null}
              </ExpandableCard>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setShowModal(false)} disabled={submitting}>
                Close
              </Button>
              <Button id="save-block" type="submit" color="success" disabled={submitting}>
                Save
                {submitting && (
                  <span className="pl-2">
                    <Spinner size="sm">{""}</Spinner>
                  </span>
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      )}
    </Form>
  );
});

export default MenuItemModal;
