/* eslint-disable no-unsafe-optional-chaining */
import algoliasearch from "algoliasearch/lite";
import _, { find, map } from "lodash";
import React, { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { Badge, Button, Card, CardBody, CardTitle, Table } from "reactstrap";
import { getConfig } from "../../../../core/utilities";
import { useAppSelector } from "../../../../hooks/hook";
import { Menus } from "../../../../resbutler-utils/types/Menu";
import { getSizeDetail } from "../../helper";
import ProductForm from "../common/ProductForm";
import { ProductSearchModal } from "./../../../../resbutler-ui/components/ProductSearch/ProductSearchModal";
import { ProductSizes, StockLimits } from "./../../../../resbutler-utils/types/product";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID, process.env.REACT_APP_ALGOLIA);

const FOOD_AND_BEVERAGE_TYPEID = 1;
const COMBINATION_FOOD_AND_BEVERAGE_TYPEID = 8;
const BEVERAGE_TYPEID = 16;

interface SpecialProps {
  menus?: Menus;
  stockLimits: StockLimits;
  restaurantId: string;
  productSizes: ProductSizes;
}

function Special({ menus, stockLimits, restaurantId, productSizes }: SpecialProps) {
  const { tenantId, client: clientId, publicStorageBucket } = getConfig();
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [products, setProducts] = useState<any>([]);
  const [showSearchProductModal, setSearchProductModal] = useState<boolean>(false);
  const [collapse, setCollapse] = useState<any>({});
  const { allProducts, menuHeadings } = useAppSelector((state) => ({
    allProducts: state.root.allProducts,
    menuHeadings: state.root.menuHeadings,
  }));
  const values = useFormState().values as StockLimits;
  const form = useForm();

  function toggleCollapse(tab) {
    const tab_state = collapse?.[tab] ?? false;
    setCollapse({ ...collapse, [tab]: !tab_state });
  }

  async function fetchProducts(productIds) {
    const products = [] as any;

    for (let productId of productIds) {
      const product1 = find(allProducts, (p) => p.id === productId);
      const product = Object.assign({}, product1);
      if (restaurantId in product?.restaurants && product?.restaurants?.[restaurantId].enabled) {
        product.productId = productId;

        const selectedProductSizeIds = Object.keys(selectedProducts[productId]);
        product.sizeIds = [];

        // check product size has pre-defined standard price or not, if not exclude that size
        for (let sizeId of selectedProductSizeIds) {
          if (sizeId in product.restaurants[restaurantId].price && product.restaurants[restaurantId].price[sizeId].length) {
            product.sizeIds.push(sizeId);
          }
        }

        if (product.sizeIds.length) {
          products.push(product);
        }

        // remove sizes
        const available_sizes = Object.entries(product?.sizes ?? {}).reduce((accu, [key, value]) => {
          if (selectedProductSizeIds.indexOf(key) !== -1) {
            accu[key] = value;
          }
          return accu;
        }, {});

        product.sizes = available_sizes;
      }
    }

    setProducts(products);

    const collapseIds = Object.keys(values?.specials || {}).reduce((accu, id) => {
      accu[id] = true;
      return accu;
    }, {});

    setCollapse((oldState) => {
      return { ...oldState, collapseIds };
    });
  }

  // remove specific size from product
  function updateProducts(productId, sizeId) {
    if (!productId || !sizeId) {
      return;
    }

    const specialMenuItems = products.map((product: any) => {
      if (product.productId === productId) {
        // remove size Id
        const sizeIdIndex = product.sizeIds.indexOf(sizeId);
        if (sizeIdIndex !== -1) {
          product.sizeIds.splice(sizeIdIndex, 1);

          if (values?.specials?.[productId]?.[sizeId]) {
            // reduce count
            form.change(`count.specials`, values.count.specials - 1);
            // remove selected size
            form.change(`specials.${productId}.${sizeId}`, undefined);
          }
        }
      }

      return product;
    });

    // remove product if sizes are not available
    const specialMenuItemsWithSizeIds = specialMenuItems.filter((specialMenuItem) => {
      if (!specialMenuItem.sizeIds.length && values?.specials?.[productId]) {
        form.change(`specials.${productId}`, undefined);
      }

      return specialMenuItem.sizeIds.length > 0;
    });

    setProducts(specialMenuItemsWithSizeIds);
  }

  useEffect(() => {
    if (Object.keys(selectedProducts)?.length) {
      const productIds = Object.keys(selectedProducts);
      if (productIds.length) {
        // update added product count
        form.change(`count.specials`, productIds.length);
        fetchProducts(productIds);
      }
    }
  }, [selectedProducts]);

  // get all specials product and render all products
  useEffect(() => {
    const productIds = {};

    const specials = stockLimits?.specials ?? {};
    Object.entries(specials).map(([productId, sizeObject]) => {
      const sizeIds = Object.keys(sizeObject || ({} as any));

      productIds[productId] = sizeIds.reduce((accu, sizeId) => {
        accu = { ...accu, [sizeId]: true };

        return accu;
      }, {});

      setSelectedProducts(productIds);
    });
  }, [stockLimits]);

  return (
    <React.Fragment>
      <Button className="mb-3" type="button" color="primary" onClick={() => setSearchProductModal(true)}>
        Add Product
      </Button>
      <Table bordered className="sc-table m-0">
        <tbody>
          {products.length > 0 &&
            products.map((product) => {
              const { productId, name } = product;
              let sizeItems = getSizeDetail(productSizes, product.sizeIds);
              return (
                <React.Fragment key={productId}>
                  <tr>
                    <td>
                      <Button type="button" className="mr-2" onClick={() => toggleCollapse(productId)}>
                        <i className={!collapse[productId] ? "fa fa-chevron-right" : "fa fa-chevron-down"} aria-hidden="true" />
                      </Button>
                      {name}
                      <Badge color="light" className="ml-1">
                        {values?.specials?.[productId] &&
                        Object.values(values.specials[productId] || []).some((size: any) => {
                          return "isOverride" in size && size.isOverride;
                        })
                          ? "override"
                          : ""}
                      </Badge>
                    </td>
                  </tr>
                  {collapse[productId] ? (
                    <tr className="p-2">
                      <td className="bg-light">
                        <div className="menu-items">
                          {map(sizeItems, (size) => {
                            return (
                              <Card key={`special-product-${productId}-${size.id}`}>
                                <CardBody className="border rounded">
                                  <CardTitle className="font-weight-bold">{size?.name}</CardTitle>
                                  <ProductForm restaurantId={restaurantId} updateProducts={updateProducts} restaurantMenuHeadings={menuHeadings} stockLimitKey="specials" product={product} productId={productId} menus={menus} size={size} />
                                </CardBody>
                              </Card>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}
        </tbody>
      </Table>

      {/* Product Search Modal */}
      <ProductSearchModal
        clientId={clientId}
        publicStorageBucket={publicStorageBucket}
        initialValues={products}
        open={showSearchProductModal}
        setOpen={setSearchProductModal}
        onCloseData={(data) => {
          if (data.length) {
            const productsById = data.reduce((accu, current) => {
              accu[current.productId] = current.sizes;

              return accu;
            }, {});

            setSelectedProducts(productsById);
          } else {
            form.change(`count.specials`, 0);
            form.change(`specials`, {});
            setProducts([]);
          }

          setSearchProductModal(false);
        }}
        query={`typeId:${FOOD_AND_BEVERAGE_TYPEID} OR typeId:${COMBINATION_FOOD_AND_BEVERAGE_TYPEID} OR typeId:${BEVERAGE_TYPEID}`}
        tenantId={tenantId}
        searchClient={searchClient}
        restaurantId={restaurantId}
        productSizes={_.values(productSizes)}
      />
      {/* Product Search Modal: End */}
    </React.Fragment>
  );
}

export default Special;
