import _, { keys } from "lodash";
import { useSelector } from "react-redux";
import { OperationMode } from "../components/Layout/Offsidebar";
import { DisplayType, OrderModeType, ProgressType } from "../components/Layout/OmsSelector";
import { isDocketCompleted } from "../helpers/dockets";
import { DocketFromDocketDocketItems, OMSDocketGeneratedFrom } from "../resbutler-utils/types/Docket";
import { OrderItemStatus } from "../resbutler-utils/types/Order";
import { StateType } from "../store/reducers/reducers";
import { getOperationModeSelector } from "../store/reducers/rootSlice";

export const useFilteredDockets = (hiddenKey: string) => {
  const { display, linkedTable, operationMode, progress, orderMode, dockets, showHiddenDockets } = useSelector((state: StateType) => ({
    display: state.root.display,
    linkedTable: state.root.linkedTable,
    operationMode: getOperationModeSelector(state),
    progress: state.root.progress,
    orderMode: state.root.orderMode,
    dockets: state.root.dockets,
    showHiddenDockets: state.settings.showHiddenDockets,
  }));

  const tableLinkFilter = (docket) => linkedTable === docket.tableNo;

  let filteredDockets = dockets.filter((d) => d.docketGeneratedFrom === OMSDocketGeneratedFrom.Docket);
  let originalDockets = filteredDockets;
  if (linkedTable) {
    filteredDockets = dockets.filter(tableLinkFilter);
  }

  if (orderMode === OrderModeType.PreOrder) {
    filteredDockets = dockets;
  } else if (orderMode === OrderModeType.CurrentBasketOrder) {
    filteredDockets = dockets;
  } else if (orderMode === OrderModeType.SentOrder) {
    if (operationMode === OperationMode.OneStep) {
      if (progress === ProgressType.Active) {
        filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Prepared));
      } else if (progress === ProgressType.Completed) {
        filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
      } else if (progress === ProgressType.Removed) {
        filteredDockets = filteredDockets.filter((docket) => (docket.docketItems as DocketFromDocketDocketItems[]).every((di) => di.orderStatus === undefined));
      }
    } else if (operationMode === OperationMode.AllStep) {
      if (display === DisplayType.PrepDisplay) {
        if (progress === ProgressType.Active) {
          filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Sent));
        } else if (progress === ProgressType.Completed) {
          filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
        } else if (progress === ProgressType.Removed) {
          filteredDockets = filteredDockets.filter((docket) => (docket.docketItems as DocketFromDocketDocketItems[]).every((di) => di.orderStatus === undefined));
        }
      } else if (display === DisplayType.ServeDisplay) {
        if (progress === ProgressType.Active) {
          filteredDockets = filteredDockets.filter((docket) => docket.docketItems.some((di) => di.orderStatus <= OrderItemStatus.Prepared));
        } else if (progress === ProgressType.Completed) {
          filteredDockets = filteredDockets.filter((docket) => isDocketCompleted(docket, operationMode, display));
        } else if (progress === ProgressType.Removed) {
          filteredDockets = filteredDockets.filter((docket) => (docket.docketItems as DocketFromDocketDocketItems[]).every((di) => di.orderStatus === undefined));
        }
      }
    }
  }

  // show hidden dockets on the active progress filter
  if (showHiddenDockets) {
    filteredDockets = filteredDockets.filter((docket) => docket.hidden[hiddenKey]);
  } else {
    filteredDockets = filteredDockets.filter((docket) => !docket.hidden[hiddenKey]);
  }

  const completedDocketNumber = _.filter(originalDockets, (d) => {
    return isDocketCompleted(d, operationMode, display);
  }).length;

  const removedDocketNumber = _.filter(originalDockets, (d) => {
    return d.docketItems.every((di) => di.orderStatus === undefined);
  }).length;

  const allCount = keys(originalDockets).length;
  const activeDockets = keys(originalDockets).length - completedDocketNumber - removedDocketNumber;

  return { filteredDockets, completedDocketNumber, removedDocketNumber, allCount, activeDockets };
};
