import { ProductCategoryId } from "./ProductCategoryId";
import { ProductGroupHierachy } from "./ProductGroupHierachy";

export enum GroupProductsPaymentSplitType {
  ProRata = 1,
  ManualSplit,
}

export interface ProductTagAttributes {
  display: boolean;
  value: string;
  abbreviation: string;
}

export interface ProductTags {
  [tagName: string]: ProductTagAttributes[];
}

export interface ContactDetails {
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  mobile?: string;
}
export interface CustomerDetails {
  businessName?: string;
  businessStructure?: string;
  businessNumber?: string;
  businessAddress?: string;
  businessCity?: string;
  businessState?: string;
  businessCountry?: string;
  businessPostcode?: string;
  firstName?: string;
  lastName?: string;
  position?: string;
  email?: string;
  mobile?: string;
  enableSecondaryContact?: boolean;
  secondaryContact?: ContactDetails;
  enableBillingContact?: boolean;
  billingContact?: ContactDetails;
  contracts?: any;
  additionalDocuments?: any;
}
export interface TradeReference {
  businessName?: string;
  mobile?: string;
  email?: string;
  addressLine?: string;
  city?: string;
  state?: string;
  referenceCheck?: string;
  tradeReferencesDocuments?: any[];
  enabled?: boolean;
}
export interface Reference {
  tradeReferences?: TradeReference[];
  organisationName?: string;
  referenceCheck?: string;
  addAnotherTradeReference?: boolean;
  creditReferenceDocuments?: any[];
}

export interface Product {
  _key?: string;
  id: string;
  name: string;
  description: string;
  docketName?: string;
  pluBarcode?: string;
  groupId: string;
  supplier?: string;
  docketSettings?: {
    [restaurantId: string]: {
      [printerId: string]: boolean;
    };
  };
  order?: number;
  groupProducts?: SizeIdMap<ComboGroup[]>;
  groupProductsPaymentSplitType: GroupProductsPaymentSplitType;
  ingredients: any;
  timeValue?: string;
  image?: {
    ext: string;
    fileName: string;
    name: string;
    sizeFile: number;
    thumbUrl: string;
    type: string;
    url: string;
  };
  overrideGroupDocketSettings?: {
    [restaurantId: string]: boolean;
  };
  orderingRules?: {
    preOrder: boolean;
    preOrderDays: number;
    completionTime: number;
    showSingleSizeInOrdering?: boolean;
    showSingleSizeInDocket?: boolean;
  };
  enableOrderingAppProductFiltering: boolean;
  butlerService: {
    subcategoryId: string;
  };
  translateName: {
    [language: string]: any;
  };
  translateDescription: {
    [language: string]: any;
  };
  salesTax: "Included" | "";
  sizes: SizeIdMap<boolean>;
  restaurants: {
    [restaurantId: string]: ProductInfo;
  };
  fixedPriceMenuTypes: {
    adult: "adult" | "child";
  };
  fixedMenuPriceSplit?: {
    restaurants: {
      [id: string]: { food: number; beverage: number };
    };
  };
  displayOnMenuOrderingApp?: boolean;
  tags?: {
    [tagName: string]: {
      display: boolean;
      value: string;
      abbreviation: string;
    }[];
  };
  // Product enhancements
  preparations?: ProductPreparation[];
  additions: ProductAddition[];
  modifiers: ProductModifier[];
  upsells: ProductUpsell[];
  upgrades?: ProductUpgrade[];
  typeId: ProductTypeId;
  productCategoryId: ProductCategoryId;
  enabled?: boolean;
  recipeDescription?: string;
  recipeIngredients?: string;
  recipeMethod?: string;
  additionalInformation?: string;
  productGroupHierachy: ProductGroupHierachy[];
  flags?: ProductFlags;
  customer?: CustomerDetails;
  references?: Reference;
}

export interface Exclusions {
  [productId: string]: {
    [productSizeId: string]: boolean;
  };
}

export interface SpecialProduct {
  productId: string;
  sizeId: string;
  menuId: string;
  headingId: string;
  price: number;
  badgeText: string;
  menuHeadingId: string;
}
export interface GroupHeadingHierachy {
  name: string;
  groupHeadingId: string;
}

export interface ProductSearchResult {
  productId: string;
  sizes: {
    [productSizeId: string]: boolean;
  };
}

export enum ProductTypeId {
  food = 1,
  fixedPriceMenu = 2,
  fixedPrice = 3,
  financial = 4,
  // credit = 5,
  // pettyCash = 6,
  combinationFoodAndBeverage = 8,
  beverage = 16,
  // noShow = 17,
  // cancellationFee = 18,
  // prepaid = 32,
  time = 128,
  tableClass = 256,
  // ingredient = 1024,
  depositMinSpend = 2048,
  marketingPromotional = 4096,
  customerServiceExpense = 4097,
  operationalExpense = 4098,
  pettyCashExpense = 4099,
  contingentLiability = 4100,
}

export type AllowedEnhancementProductTypes = "Beverage" | "Food";

export type SizeIdMap<T> = { [sizeId: string]: T };

export type ComboGroupProduct = {
  sizes: SizeIdMap<boolean>;
  sortSequence?: number;
  productId?: string;
};
export type CombinationMap = {
  [productId: string]: ComboGroupProduct;
};
export interface ComboGroup {
  restaurants: {
    [restaurantId: string]: {
      price: number;
    };
  };
  combinations: CombinationMap;
  name?: string;
  quantity: number;
  includeAllCombinations?: boolean;
}

export interface ModifierGroupProduct {
  order?: number;
  productId: string;
  sizes: SizeIdMap<boolean>;
}
export interface Modifier {
  id?: string;
  enabled: boolean;
  mandatory?: boolean;
  menuDisplayName?: string;
  // modifierGroupId: string;
  modifierType?: "Beverage" | "Food";
  name: string;
  products?: ModifierGroupProduct[];
}

export interface ModifierGroups {
  [modifierGroupId: string]: Modifier;
}

export interface AdditionGroupProduct {
  order: number;
  productId: string;
  sizes: SizeIdMap<boolean>;
}

export interface ProductSize {
  productContainerId?: string;
  value?: number;
  units?: string;
  enabled: boolean;
  name?: string;
  abbreviation?: string;
  id?: string;
  byUnits?: string;
  order: number;
  useContainerAsName?: boolean;
}

export interface ProductSizes {
  [productSizeId: string]: ProductSize;
}
export interface ProductSizeContainer {
  id?: string;
  name: string;
  enabled: boolean;
}

export interface ProductSizeContainers {
  [productSizeContainerId: string]: ProductSizeContainer;
}

export interface PreparationGroup {
  id?: string;
  enabled: boolean;
  name: string;
  menuDisplayName?: string;
  // preparationGroupId: string;
  mandatory?: boolean;
  preparationRequirements?: PreparationGroupProduct[];
  preparationType?: "Beverage" | "Food";
}

export interface PreparationGroupProduct {
  id: string;
  name: string;
  order: number;
}

export interface PreparationGroups {
  [preparationGroupId: string]: PreparationGroup;
}

export interface UpsellGroupProduct {
  order: number;
  productId: string;
  sizes: SizeIdMap<boolean>;
}

export interface UpsellGroup {
  id?: string;
  enabled: boolean;
  name: string;
  products?: UpsellGroupProduct[];
  upsellType?: "Beverage" | "Food";
}

export interface UpsellGroups {
  [upsellGroupId: string]: UpsellGroup;
}

/** Add Upgrade group types */
export interface UpgradeGroupProduct {
  order: number;
  productId: string;
  sizes: SizeIdMap<boolean>;
}

export interface UpgradeGroup {
  id?: string;
  enabled: boolean;
  name: string;
  products?: UpgradeGroupProduct[];
  upgradeType?: AllowedEnhancementProductTypes;
}

export interface UpgradeGroups {
  [upgradeGroupId: string]: UpgradeGroup;
}

export interface PromotionGroupProduct {
  order: number;
  productId: string;
  sizes: SizeIdMap<boolean>;
}

export interface PromotionGroup {
  id?: string;
  enabled: boolean;
  name: string;
  products?: PromotionGroupProduct[];
  promotionType?: AllowedEnhancementProductTypes;
}
export interface PromotionGroups {
  [promotionGroupId: string]: PromotionGroup;
}

export interface Products {
  [productId: string]: Product;
}

export interface PriceOption {
  _key?: string;
  id?: string;
  name: string;
  nearest: number;
  priceAdjustment: number;
  rounding: "roundUp" | "";
  showWithStandardPrice?: boolean;
}

export interface PriceOptions {
  [priceOptionId: string]: PriceOption;
}

export interface ProductTag {
  id: string;
  name: string;
  /** @deprecated use ordering and booking instead */
  values?: {
    [tag: string]: boolean;
  };
  restaurantId: string;
  ordering: NameMap<boolean>;
  booking: NameMap<boolean>;
}

export interface AdditionGroup {
  id?: string;
  additionType?: "Beverage" | "Food";
  enabled?: boolean;
  name: string;
  products?: AdditionGroupProduct[];
}

export interface AdditionGroups {
  [additionGroupId: string]: AdditionGroup;
}

export interface AccountMappings {
  refundProductId: string;
  wastageProductId: string;
  cancellationFeeProductId: string;
  tabProductId: string;
  functionInvoiceProductId: string;
  menuAdjustmentProductId: string;
  butlerServiceProductId: string;
  giftCertificateProductId: string;
  paymentPriceIncreaseAdjustments: string[];
  paymentPriceDecreaseAdjustments: string[];
  cashoutProductId: string;
  creditAccountGroupId: string;
  contraGroupId: string;
  deferredVoucherProductId: string;
}

export interface PackageOverridePricesProduct {
  [productId: string]: SizeIdMap<number>;
}

export enum ProductGroupPriceAdjustmentType {
  Increase = "increase",
  Decrease = "decrease",
}
export interface ProductGroup {
  id?: string;
  _key?: string;
  calculationTypeId: any;
  categoryId: string;
  description: string;
  enableButlerService?: boolean;
  enabled?: boolean;
  name: string;
  order?: number;
  parentGroup: string;
  docketSettings?: {
    [restaurantId: string]: {
      [printerId: string]: boolean;
    };
  };
  overrideGroupDocketSettings?: {
    [restaurantId: string]: boolean;
  };
  requiredPrepayment?: boolean;
  requiresTextInput?: boolean;
  textInput: any;
  productCategoryId: ProductCategoryId;
  systemGroup?: boolean;
}

export interface ProductGroups {
  [productGroupId: string]: ProductGroup;
}

export interface Pricing {
  [productId: string]: SizeIdMap<number>;
}

export interface StockLimitProduct {
  [sizeId: string]: {
    menus: {
      [menuId: string]: {
        badgeText?: string;
        menuHeadingId: string;
        price?: number;
      };
    };
    isOverride?: boolean;
    status: ProductStockStatus;
    appliedTo?: "applied" | "total" | "menus" | "dineIn_takeaways";
    outOfStockLabel?: "sold_out";
    stock?: {
      menus: {
        [menuId: string]: number;
      };
      dineIn: number;
      takeaway: number;
      current: number;
    };
  };
}

export interface StockLimitProducts {
  [productId: string]: StockLimitProduct;
}

export interface StockLimits {
  count?: {
    additions: number;
    modifiers: number;
    upsells: number;
    products: number;
    specials: number;
    upgrades: number;
  };
  specials?: StockLimitProducts;
  products?: StockLimitProducts;
  modifiers?: StockLimitProducts;
  additions?: StockLimitProducts;
  upsells?: StockLimitProducts;
  // upgrades?: StockLimitProducts;
}
export type ProductStockStatus = "applied" | "sold" | "hide";

export type ProductFlags = {
  useAsAddition?: boolean;
  useAsModifier?: boolean;
};

export type ProductPreparation = {
  mandatory?: boolean;
  menuDisplayName?: string;
  preparationGroupId: string;
  totalselections?: number;
  limitedSelection?: boolean;
  exclusions?: {
    [preparationRequirementId: string]: boolean;
  };
  sizes?: SizeIdMap<boolean>;
};

export interface ProductModifier {
  mandatory?: boolean;
  menuDisplayName?: string;
  modifierGroupId: string;
  exclusions: Exclusions;
}

export interface ProductAddition {
  mandatory?: boolean;
  menuDisplayName?: string;
  additionGroupId: string;
  totalselections?: number;
  limitedSelection?: boolean;
  exclusions: Exclusions;
}

export interface ProductUpsell {
  menuDisplayName?: string;
  upsellGroupId: string;
  exclusions: Exclusions;
}

export interface ProductUpgrade {
  menuDisplayName: string;
  upgradeGroupId: string;
  exclusions: Exclusions;
}

export interface ProductInfo {
  enabled: boolean;
  description?: string;
  image?: {
    ext: string;
    fileName: string;
    name: string;
    sizeFile: number;
    thumbUrl: string;
    type: string;
    url: string;
  };
  price: SizeIdMap<number> | number; // some product's don't have sizes
  sizes?: SizeIdMap<number>;
}

export type NameMap<T> = { [name: string]: T };

// type for src/resbutler-utils/files/foodIntolerances.json
export interface FoodIntolerance {
  name: string;
}

interface DietaryRequirement extends FoodIntolerance {
  abbreviation?: string;
}

interface FishAndSeafood {
  "Fish & Seafood": FoodIntolerance[];
}

interface MeatAndPoultry {
  "Meat & Poultry": FoodIntolerance[];
}

interface AdditiveCategory {
  Additives: FoodIntolerance[];
  Oils: FoodIntolerance[];
}

interface SavoryFood {
  Savoury: FoodIntolerance[];
}

interface SweetsAndSnacks {
  "Sweets & Snacks": FoodIntolerance[];
}

interface Drinks {
  "Non-Alcoholic": FoodIntolerance[];
  Alcohol: FoodIntolerance[];
}

export interface FoodIntolerances {
  Allergies: FoodIntolerance[];
  "Dietary Requirements": DietaryRequirement[];
  "Religious Preparation": FoodIntolerance[];
  Sourcing: FoodIntolerance[];
  "Primary Ingredients": (FoodIntolerance | FishAndSeafood | MeatAndPoultry)[];
  "Ingredients of Concern": AdditiveCategory[];
  "Food Types": (SavoryFood | SweetsAndSnacks)[];
  "Drink Types": Drinks[];
  "Cooking Preparations": FoodIntolerance[];
}
