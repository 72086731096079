/* eslint-disable no-unsafe-optional-chaining */
import { assign, keys, map, sortBy, startsWith } from "lodash";
import React, { useEffect, useState } from "react";
import { useFormState } from "react-final-form";
import { Badge, Button, Card, CardBody, CardTitle, Spinner, Table } from "reactstrap";
import { Menus } from "../../../../resbutler-utils/types/Menu";
import { Product, ProductSizes, StockLimits } from "../../../../resbutler-utils/types/product";
import ModifierForm from "../common/ProductForm";
import { useAppSelector } from "./../../../../hooks/hook";
import { FilterProps } from "./../../Filter";

interface ModifierProps {
  menus: Menus;
  restaurantId: string;
  filteredProducts: Product[];
  loading?: boolean;
  filters?: FilterProps;
  productSizes?: ProductSizes;
}

function Modifier({ menus, restaurantId, filteredProducts, loading, filters, productSizes }: ModifierProps) {
  const values = useFormState().values as StockLimits;
  const [collapse, setCollapse] = useState({});
  const [filterProducts, setFilterProducts] = useState<Product[]>([]);
  const { modifierGroups, allProducts } = useAppSelector((state) => ({
    modifierGroups: state.root.modifierGroups,
    allProducts: state.root.allProducts,
  }));

  const getProduct = (productId) => filteredProducts.find((item) => item.id == productId);

  useEffect(() => {
    let modifierProducts1 = [] as Product[];
    for (let i = 0; i < modifierGroups.length; i++) {
      const ag = modifierGroups[i];
      for (let j = 0; j < ag?.products?.length; j++) {
        const product = getProduct(ag.products[j].productId);
        if (product && !modifierProducts1.find((item) => item.id == ag.products[j].productId)) {
          const productObj = assign({}, product) as Product;
          productObj.sizes = ag.products[j].sizes;
          modifierProducts1.push(productObj);
        }
      }
    }
    if (filters.query && filters.query.trim()?.length) {
      modifierProducts1 = modifierProducts1.filter(({ name }: any) => startsWith(name.toLowerCase(), filters.query.trim().toLowerCase()));
    }
    setFilterProducts(sortBy(modifierProducts1, (p) => p.name));
  }, [filteredProducts, allProducts, modifierGroups, filters.query]);

  // manage accordion for product
  function toggleCollapse(tab) {
    const tab_state = collapse?.[tab] ?? false;
    setCollapse({ ...collapse, [tab]: !tab_state });
  }

  return (
    <React.Fragment>
      <Table bordered className="sc-table">
        <tbody>
          {!loading ? (
            map(filterProducts, ({ id, sizes, name }: Product) => {
              return (
                <React.Fragment key={id}>
                  <tr>
                    <td>
                      <Button type="button" className="mr-2" onClick={() => toggleCollapse(id)}>
                        <i className={!collapse[id] ? "fa fa-chevron-right" : "fa fa-chevron-down"} aria-hidden="true" />
                      </Button>
                      {name}
                      <Badge color="light" className="ml-1">
                        {id in values?.modifiers && Object.values(values.modifiers[id]).some(({ isOverride = false }: any) => !!isOverride) ? "override" : ""}
                      </Badge>
                    </td>
                  </tr>
                  {collapse[id] ? (
                    <tr className="p-2">
                      <td className="bg-light">
                        <div className="menu-items">
                          {map(keys(sizes), (sizeId: string) => {
                            if (!(sizes[sizeId] && productSizes?.[sizeId])) return null;
                            return (
                              <Card key={`modifier-product-${id}-${sizeId}`}>
                                <CardBody className="border rounded">
                                  <CardTitle className="font-weight-bold">{productSizes[sizeId]?.name}</CardTitle>
                                  <ModifierForm stockLimitKey="modifiers" productId={id} menus={menus} size={productSizes[sizeId]} restaurantId={restaurantId} />
                                </CardBody>
                              </Card>
                            );
                          })}
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })
          ) : (
            <Spinner size="sm" className="mr-2">
              {""}
            </Spinner>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default Modifier;
