export interface VoucherSetups {
  [voucherId: string]: VoucherSetup;
}

export enum VoucherTypes {
  DeferredBookingVoucher = 1,
  Promotion = 2,
  Donation = 3,
}

export enum GenerationType {
  oneOff = "oneOff",
  recurring = "recurring",
}

export type VoucherSetup = {
  _key?: string;
  name: string;
  description: string;
  restaurants: string[];
  products: VoucherProduct[];
  subjectLine: string;
  type: "loyalty" | "oneOff";
  voucherType: VoucherTypes;
  reasonType?: string;
  voucherAmount: number;
  allProducts: boolean;
  discountType: "%" | "$" | "product"; // %, $
  discountAmount: number;
  complementaryProducts?: VoucherProduct[];
  redeemQuantity: number;
  voucherExpiryNumber: number;
  voucherExpiryType: "month";
  generationType: GenerationType;
  isExpirable: boolean;
  voucherGenerateOn: any;
  voucherGenerateExpiry: any; // firebase.firestore.Timestamp;
  isEnabled: boolean;
  staffNote?: string;
  sendEmail: boolean;
  template: {
    templateId: string;
    headerText1: string;
    bodyText1: string;
  };
  customerFilters: {
    filterType: "all" | "specific" | "filter";
    selectedFilters: string[]; // 'Birthday', 'Membership'
    membershipLevels: string[];
    age: {
      minAge: string;
      maxAge: string;
    };
    birthdayMonth: string;
    specificCustomers: string[];
    specificCustomerObjects: { label: string; value: string }[];
  };
  bookingId?: string;
};

// type OneOffGenerationOn = {
//   generationType: GenerationType.oneOff;
//   voucherGenerateOn: any; //firebase.firestore.Timestamp;
// };
// type RecurringGenerationOn = {
//   generationType: GenerationType.recurring;
//   voucherGenerateOn: any;
// };

export interface ReasonTypes {
  [reasonTypeId: string]: ReasonType;
}

export interface ReasonType {
  name: string;
  voucherType: VoucherTypes;
  enabled?: boolean;
}

export interface VoucherIssueLog {
  issuedDate: any;
  voucherSetup: VoucherSetup;
  voucherSetupId: string;
}

export interface VoucherProduct {
  productId: string;
  sizes: { [sizeId: string]: boolean };
}

export interface Voucher {
  id?: string;
  voucherSetupId: string;
  customerId: string;
  voucherNumber: string;
  restaurants: string[];
  voucherType: VoucherTypes;
  reasonType?: string;
  voucherAmount?: number; // todo:check if this is still relevant
  redeemQuantityLeft: number;
  allProducts: boolean;
  redeemedInfo: {
    redeemedDate: number;
    transactionId: string;
    purchaseId: string;
  }[];
  products?: VoucherProduct[];
  discountType: "%" | "$" | "product";
  discountAmount: number;
  issuedDate: any;
  expiryDate: any;
  isRedeemed: boolean;
  isEnabled: boolean;
}
export interface EmailBrandingTemplate {
  _key?: string;
  name: string;
  restaurantId: string;
  brandColor?: { r: number; g: number; b: number; a: number };
  backgroundColour?: { r: number; g: number; b: number; a: number };
  buttonBackgroundColor?: { r: number; g: number; b: number; a: number };
  logo: string;
  largeImage1: string;
  buttonText1: string;
  buttonLink1: string;
  linkFacebook: string;
  linkInstagram: string;
  linkTwitter: string;
  linkYouTube: string;
  address: string;
  website: string;
  isEnabled: boolean;
}
